<div class="row">
  <div class="col-lg-9">
    <h4 class="card-title">Parcel Count & Date Count Table</h4>
<!--    <ngx-charts-bar-horizontal [barPadding]="20" [gradient]="false" [legend]="false"-->
<!--                               [results]="chartDataFeed" [roundDomains]="false"-->
<!--                               [schemeType]="schemeType" [scheme]="colorScheme" [showGridLines]="true"-->
<!--                               [showXAxisLabel]="true"-->
<!--                               [showYAxisLabel]="true"-->
<!--                               [tooltipDisabled]="false" [xAxisLabel]="xAxisLabel" [xAxis]="true"-->
<!--                               [yAxisLabel]="yAxisLabel"-->
<!--                               [yAxis]="true" class="chart-container">-->
<!--    </ngx-charts-bar-horizontal>-->
  </div>

  <div class="col-lg-3">

    <div class="card">
      <div class="card-body">
        <div class="row">
          <label class="form-control-label">Employee Name</label>
          <ng-autocomplete
            (change)="printValues()"
            (selected)='selectedEmployee($event)'
            [data]="allUsers"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            [placeHolder]="currentSelectedEmployeeName"
            [searchKeyword]="keyword">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <label class="form-control-label">Date Or Date-Range</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [dayTemplate]="t" [displayMonths]="1" ngModel>
            </ngb-datepicker>

            <ng-template #t let-date="date" let-focused="focused">
                <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                      [class.faded]="isHovered(date) || isInside(date)"
                      [class.focused]="focused"
                      [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                      class="custom-day">
                    {{ date.day }}
                </span>
            </ng-template>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
