<div class="row">
  <div class="col-lg-12">
    <nb-card class="card">
      <nb-card-body class="card-body">
        <form class="w-50 m-b-30">
          <div class="btn-group mr-3">
<!--            <input (change)="filterBySearch($event)" class="form-control btn-group text-field-controller outline-primary" placeholder="Search..." type="text" />-->

            <div aria-label="Button group with nested dropdown" class="btn-group" ngbDropdown role="group">
              <button class="btn btn-outline-primary" ngbDropdownToggle>{{deliveryStatusBtn}}</button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button (click)="filterWithDeliveryStatus('Default')" class="dropdown-item pointer">Default</button>
                <button (click)="filterWithDeliveryStatus('Pending')" class="dropdown-item pointer">Pending</button>
                <button (click)="filterWithDeliveryStatus('Confirmed')" class="dropdown-item pointer">Confirmed</button>
                <button (click)="filterWithDeliveryStatus('Dispatched')" class="dropdown-item pointer">Dispatched</button>
                <button (click)="filterWithDeliveryStatus('Delivered')" class="dropdown-item pointer">Delivered</button>
                <button (click)="filterWithDeliveryStatus('Canceled')" class="dropdown-item pointer">Canceled</button>
                <button (click)="filterWithDeliveryStatus('Return')" class="dropdown-item pointer">Return</button>
                <button (click)="filterWithDeliveryStatus('Warrenty')" class="dropdown-item pointer">Warranty</button>
              </div>
            </div>

            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Main Orders', sheet: 'Order_details', Props: {Author: 'Expergen Solutions'}})">Excel</button>
            <button mat-raised-button (click)="exporter.exportTable('csv', {fileName:'Main Orders', sheet: 'Order_details', Props: {Author: 'Expergen Solutions'}})">CSV</button>
            <button mat-raised-button (click)="exporter.exportTable('txt', {fileName:'Main Orders', sheet: 'Order_details', Props: {Author: 'Expergen Solutions'}})">Text</button>
          </div>
        </form>


        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Quick Table Search">

        <mat-slide-toggle  [(ngModel)]="updateWithAllOrders" (change)="updateDisplayingNumberOfRows()">Search in all</mat-slide-toggle>

        <div class="mat-elevation-z8">
          <table id="excel-table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="dataSource">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let element">{{dateFormatter(element.date)}}</td>
            </ng-container>

            <ng-container matColumnDef="orderNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Number</th>
              <td mat-cell *matCellDef="let element">{{element.orderNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="COD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Tracking Code</th>
              <td mat-cell *matCellDef="let element">{{element.COD}}</td>
            </ng-container>

            <ng-container matColumnDef="customerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
              <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
            </ng-container>

            <ng-container matColumnDef="phoneNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
              <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="fixedLine">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Fixed Line</th>
              <td mat-cell *matCellDef="let element">{{element.fixedLine}}</td>
            </ng-container>

            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>
              <td mat-cell *matCellDef="let element">
                <ng-template #popTitle>Order Details</ng-template>
                <ng-template #popContent>
                  <div *ngIf="!commonService.isNull(element.device1)">
                    <b>Name:</b> {{getItemName(element.device1)}} <br />
                    <b>Qty:</b> {{element.numDevice1}}
                    <hr />
                  </div>
                  <div *ngIf="!commonService.isNull(element.device2)">
                    <b>Name:</b> {{getItemName(element.device2)}} <br />
                    <b>Qty:</b> {{element.numDevice2}}
                    <hr />
                  </div>
                  <div *ngIf="!commonService.isNull(element.device3)">
                    <b>Name:</b> {{getItemName(element.device3)}} <br />
                    <b>Qty:</b> {{element.numDevice3}}
                    <hr />
                  </div>
                  <div *ngIf="!commonService.isNull(element.device4)">
                    <b>Name:</b> {{getItemName(element.device4)}} <br />
                    <b>Qty:</b> {{element.numDevice4}}
                    <hr />
                  </div>
                  <div *ngIf="!commonService.isNull(element.device5)">
                    <b>Name:</b> {{getItemName(element.device5)}} <br />
                    <b>Qty:</b> {{element.numDevice5}}
                    <hr />
                  </div>
                  <div><b>Total :</b> {{commonService.priceFormat(element.price)}}</div>
                </ng-template>
                <div placement="top" triggers="mouseenter:mouseleave" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                  <i class="fas fa-eye"></i>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="deliveryDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Date</th>
              <td mat-cell *matCellDef="let element">{{element.deliveryDate === null ? '-': element.deliveryDate}}</td>
            </ng-container>

            <ng-container matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
              <td mat-cell *matCellDef="let element">
                <ng-template #popTitle>Notes</ng-template>
                <ng-template #popContent>
                  <p *ngIf="!commonService.isNull(element.note1)">Note 1: {{element.note1}}</p>
                  <p *ngIf="!commonService.isNull(element.note2)">Note 2: {{element.note2}}</p>
                  <p *ngIf="!commonService.isNull(element.note3)">Note 3: {{element.note3}}</p>
                  <p *ngIf="commonService.isNull(element.note1) && commonService.isNull(element.note2) && commonService.isNull(element.note3)">Not Found</p>
                </ng-template>
                <div placement="top" triggers="mouseenter:mouseleave" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                  <i class="fas fa-eye"></i>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="deliveryType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Type</th>
              <td mat-cell *matCellDef="let element">{{getDeliveryTypeName(element.deliveryType)}}</td>
            </ng-container>

            <ng-container matColumnDef="deliveryStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Status</th>
              <td mat-cell *matCellDef="let element"><div class="statusLabel {{getLabelType(element.deliveryStatus)}}">{{element.deliveryStatus}}</div></td>
            </ng-container>

            <ng-container matColumnDef="agentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Agent</th>
              <td mat-cell *matCellDef="let element">
                <div ngbPopover="{{commonService.capitalize(element.agentName)}}" placement="top" triggers="mouseenter:mouseleave">
                  {{commonService.truncateValues(element.agentName, 5)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>View</th>
              <td mat-cell *matCellDef="let element" (click)="routeToSingleOrderPage(element.orderNumber)">
                <button mat-raised-button>View <i class="fa fa-arrow-right"></i></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSize]="itemsPerPage" showFirstLastButtons> </mat-paginator>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<notifier-container></notifier-container>
