<div style="text-transform: capitalize;">
  <ngx-loading-bar color="#7f6df0" height="3px" [includeSpinner]="false"></ngx-loading-bar>
  <div *ngIf="showLoader()">
    <div class="overlay show"></div>
    <div class="spanner show">
      <div class="loader"></div>
    </div>
  </div>
  <router-outlet>
    <app-spinner></app-spinner>
  </router-outlet>
</div>
