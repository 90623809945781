<div class="row">
  <div class="col-lg-8">
    <div class="card">
      <div class="card-body text-center">
        <div class="profile-pic m-b-20 m-t-20">
          <img alt="user" class="rounded-circle" src="../../../../assets/images/users/5.jpg" width="150"/>
          <h1 class="m-t-20 m-b-0 nameDetails">{{customerName}}</h1>
          <a class="niceCapital">{{currentAddress}}</a>
        </div>
        <div class="row text-center m-t-40">
          <div class="col-4">
            <h3 class="font-bold">{{orderPlacedDate}}</h3>
            <h6>Order Placed Date</h6>
          </div>
          <div class="col-4">
            <h3 class="font-bold">{{deliveryDate}}</h3>
            <h6>Delivery Date</h6>
          </div>
          <div class="col-4">
            <h3 class="font-bold niceCapital">{{getUserNameFromEmail(lastEditedBy)}}</h3>
            <h6>Last Edited By</h6>
          </div>
        </div>
      </div>
      <div class="p-25 border-top m-t-15">
        <div class="row text-center">
          <div class="col-6 border-right">
            <a class="link d-flex align-items-center justify-content-center font-medium" href="#"
               href="tel:{{phone}}"> <i class="fas fa-phone font-20 m-r-5"></i> Phone Call ({{phone}})</a>
          </div>
          <div class="col-6">
            <a class="link d-flex align-items-center justify-content-center font-medium" href="#"
               href="tel:{{telephone}}"> <i class="icon-Old-Telephone font-20 m-r-5"></i> Telephone ({{telephone}})</a>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h1 class="card-title"><b>Order Details</b></h1>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Qty</th>
                <th scope="col">Special Note</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="firstItem_edit != '' ">
                <td>{{firstItem_name_edit}}</td>
                <td>{{firstItemCount_edit}}</td>
                <td>{{specialNote1}}</td>
              </tr>
              <tr *ngIf="secondItem_edit != '' ">
                <td>{{secondItem_name_edit}}</td>
                <td>{{secondItemCount_edit}}</td>
                <td>{{specialNote2}}</td>
              </tr>
              <tr *ngIf="thirdItem_edit != '' ">
                <td>{{thirdItem_name_edit}}</td>
                <td>{{thirdItemCount_edit}}</td>
                <td>{{specialNote3}}</td>
              </tr>
              <tr *ngIf="fourthItem_edit != '' ">
                <td>{{fourthItem_name_edit}}</td>
                <td>{{fourthItemCount_edit}}</td>
                <td>{{specialNote4}}</td>
              </tr>
              <tr *ngIf="fifthItem_edit != '' ">
                <td>{{fifthItem_name_edit}}</td>
                <td>{{fifthItemCount_edit}}</td>
                <td>{{specialNote5}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr>

      <div class="card-body">
        <h5 class="p-t-20"><b>Order Special Note :</b> <span class="specialNoteContent">{{specialNotes}}</span></h5>
      </div>

      <div class="card-body">
        <h1 class="card-title"><b>Update Order Details</b></h1>
      </div>
      <hr>

      <div class="card">
        <div class="card-body">
          <h3 class="card-title">Order Entering Panel <i (click)="refreshButtonValues()"
                                                         class="sl-icon-refresh refreshButton"></i></h3>
          <h6 class="card-subtitle">This panel can enter an order to the system.</h6>

          <mat-accordion class="example-headers-align">
            <mat-expansion-panel (opened)="setStep(0)" [expanded]="step === 0" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>account_circle</mat-icon>
                  Personal data
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Customer Name</label>
                  <input [(ngModel)]="name_edit" class="form-control" ngModel type="text" value="{{name_edit}}"/>
                </div>
              </div>

              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Phone Number</label>
                  <input [(ngModel)]="phone_edit" class="form-control" ngModel type="text" value="{{phone_edit}}"/>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Telephone Number</label>
                  <input [(ngModel)]="telephone_edit" class="form-control" ngModel type="text"
                         value="{{telephone_edit}}"/>
                </div>
              </div>

              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">NIC Number</label>
                  <input [(ngModel)]="nic_edit" class="form-control" ngModel type="text" value="{{nic_edit}}"/>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Delivery Date</label>
                  <input [(ngModel)]="deliveryDate_edit" class="form-control" ngModel type="date"/>
                </div>
              </div>

              <div class="row">
                <div class="col-2 m-t-10 m-b-10">
                  <label class="form-control-label">No</label>
                  <input [(ngModel)]="addressNo_edit" class="form-control" ngModel type="text"
                         value="{{addressNo_edit}}"/>
                </div>

                <div class="col-4 m-t-10 m-b-10">
                  <label class="form-control-label">First Line</label>
                  <input [(ngModel)]="firstLine_edit" class="form-control" ngModel type="text"
                         value="{{firstLine_edit}}"/>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Second Line</label>
                  <input [(ngModel)]="secondLine_edit" class="form-control" ngModel type="text"
                         value="{{secondLine_edit}}"/>
                </div>
              </div>

              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">City</label>
                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("city",$event)'
                    [data]="cityDataListJson"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="city_edit"
                    [searchKeyword]="keyword"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Special Note</label>
                  <input [(ngModel)]="specialNotes_edit" class="form-control" ngModel type="text"
                         value="{{specialNotes_edit}}"/>
                </div>
              </div>

              <div class="row">
                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Delivery Type</label>

                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("deliveryType", $event)'
                    [data]="deliveryTypeArray"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="deliveryName"
                    [searchKeyword]="keyword">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Envelope Size</label>
                <select [(ngModel)]="envelope" class="form-control" type="text" value="{{envelope}}">
                  <option value="small">Small</option>
                  <option value="medium">Medium</option>
                  <option value="large">Large</option>
                  <option value="select">Please Select One**</option>
                </select>
              </div>

              <mat-action-row>
                <button (click)="nextStep()" color="primary" mat-button>Next</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setStep(1)" [expanded]="step === 1" hideToggle>
              <mat-expansion-panel-header class="headerBackground">
                <mat-panel-title>
                  <i class="sl-icon-social-dropbox"> Item 1 </i>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">First Item</label>
                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("item1", $event)'
                    [data]="phoneDetailsJsonArray"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="firstItem_name_edit"
                    [searchKeyword]="keyword"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Number Of Items</label>
                  <input (change)="updatePriceTable()" [(ngModel)]="firstItemCount_edit" class="form-control" min="0"
                         ngModel type="number" value="{{firstItemCount_edit}}"/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Special Note</label>
                  <input [(ngModel)]="firstItemNote_edit" class="form-control" ngModel type="text"
                         value="{{firstItemNote_edit}}"/>
                </div>
              </div>

              <mat-action-row>
                <button (click)="prevStep()" color="warn" mat-button>Previous</button>
                <button (click)="nextStep()" color="primary" mat-button>Next</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setStep(2)" [expanded]="step === 2" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <i class="sl-icon-social-dropbox"> Item 2 </i>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Second Item</label>
                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("item2", $event)'
                    [data]="phoneDetailsJsonArray"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="secondItem_name_edit"
                    [searchKeyword]="keyword"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Number Of Items</label>
                  <input (change)="updatePriceTable()" [(ngModel)]="secondItemCount_edit" class="form-control" min="0"
                         ngModel type="number" value="{{secondItemCount_edit}}"/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Special Note</label>
                  <input [(ngModel)]="secondItemNote_edit" class="form-control" ngModel type="text"
                         value="{{secondItemNote_edit}}"/>
                </div>
              </div>

              <mat-action-row>
                <button (click)="prevStep()" color="warn" mat-button>Previous</button>
                <button (click)="nextStep()" color="primary" mat-button>Next</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setStep(3)" [expanded]="step === 3" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <i class="sl-icon-social-dropbox"> Item 3 </i>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Third Item</label>

                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("item3", $event)'
                    [data]="phoneDetailsJsonArray"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="thirdItem_name_edit"
                    [searchKeyword]="keyword"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Number Of Items</label>
                  <input (change)="updatePriceTable()" [(ngModel)]="thirdItemCount_edit" class="form-control" min="0"
                         ngModel type="number" value="{{thirdItemCount_edit}}"/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Special Note</label>
                  <input [(ngModel)]="thirdItemNote_edit" class="form-control" ngModel type="text"
                         value="{{thirdItemNote_edit}}"/>
                </div>
              </div>

              <mat-action-row>
                <button (click)="prevStep()" color="warn" mat-button>Previous</button>
                <button (click)="nextStep()" color="primary" mat-button>Next</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setStep(4)" [expanded]="step === 4" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <i class="sl-icon-social-dropbox"> Item 4 </i>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Fourth Item</label>

                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("item4", $event)'
                    [data]="phoneDetailsJsonArray"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="fourthItem_name_edit"
                    [searchKeyword]="keyword"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Number Of Items</label>
                  <input (change)="updatePriceTable()" [(ngModel)]="fourthItemCount_edit" class="form-control" min="0"
                         ngModel type="number" value="{{fourthItemCount_edit}}"/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Special Note</label>
                  <input [(ngModel)]="fourthItemNote_edit" class="form-control" ngModel type="text"
                         value="{{fourthItemNote_edit}}"/>
                </div>
              </div>

              <mat-action-row>
                <button (click)="prevStep()" color="warn" mat-button>Previous</button>
                <button (click)="nextStep()" color="primary" mat-button>Next</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setStep(5)" [expanded]="step === 5" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <i class="sl-icon-social-dropbox"> Item 5</i>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Fifth Item</label>

                  <ng-autocomplete
                    (change)="updatePriceTable()"
                    (selected)='setSelectBoxValues("item5", $event)'
                    [data]="phoneDetailsJsonArray"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [placeHolder]="fifthItem_name_edit"
                    [searchKeyword]="keyword">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

                <div class="col-6 m-t-10 m-b-10">
                  <label class="form-control-label">Number Of Items</label>
                  <input (change)="updatePriceTable()" [(ngModel)]="fifthItemCount_edit" class="form-control" min="0"
                         ngModel type="number" value="{{fifthItemCount_edit}}"/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                  <label class="form-control-label">Special Note</label>
                  <input [(ngModel)]="fifthItemNote_edit" class="form-control" ngModel type="text"
                         value="{{fifthItemNote_edit}}"/>
                </div>
              </div>

              <mat-action-row>
                <button (click)="prevStep()" color="warn" mat-button>Previous</button>
                <button (click)="nextStep()" color="primary" mat-button>Next</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setStep(6)" [expanded]="step === 6" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <i class="sl-icon-chart"> Summary Of Order</i>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Sub Total</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="firstItem_edit != '' ">
                      <td>{{firstItem_name_edit}}</td>
                      <td>{{firstItemCount_edit}}</td>
                      <td>{{item1PriceSub}}</td>
                      <td><i (click)="removeAddedItem('1')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="secondItem_edit != '' ">
                      <td>{{secondItem_name_edit}}</td>
                      <td>{{secondItemCount_edit}}</td>
                      <td>{{item2PriceSub}}</td>
                      <td><i (click)="removeAddedItem('2')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="thirdItem_edit != '' ">
                      <td>{{thirdItem_name_edit}}</td>
                      <td>{{thirdItemCount_edit}}</td>
                      <td>{{item3PriceSub}}</td>
                      <td><i (click)="removeAddedItem('3')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="fourthItem_edit != '' ">
                      <td>{{fourthItem_name_edit}}</td>
                      <td>{{fourthItemCount_edit}}</td>
                      <td>{{item4PriceSub}}</td>
                      <td><i (click)="removeAddedItem('4')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="fifthItem_edit != '' ">
                      <td>{{fifthItem_name_edit}}</td>
                      <td>{{fifthItemCount_edit}}</td>
                      <td>{{item5PriceSub}}</td>
                      <td><i (click)="removeAddedItem('5')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="this.deliveryName != '' ">
                      <td colspan="2"><b>Delivery Cost ({{deliveryName}})</b></td>
                      <td>{{deliveryPrice}}</td>
                    </tr>
                    <tr *ngIf="this.orderType == 'warrantyClaim' ">
                      <td colspan="2"><b>Set Total Price</b></td>
                      <td><input (change)="updatePriceTable()" [(ngModel)]="warrantyTotalPrice" class="form-control"
                                 ngModel
                                 type="text" value="{{warrantyTotalPrice}}"/></td>
                    </tr>
                    <tr>
                      <td colspan="2"><b>Total Price</b></td>
                      <td>{{totalPrice}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <mat-action-row>
                <button (click)="prevStep()" color="warn" mat-button>Previous</button>
                <button (click)="updateOrderDetails()" color="primary" mat-button>Update</button>
              </mat-action-row>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-4">
    <div class="card">
      <div class="card-body mainBlockColor {{backgroundColorOfBlock}}">
        <br/>
        <h1 class="orderDetails">{{currentCodNumber}} | {{currentDeliveryStatus}}</h1>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <mat-tab-group [selectedIndex]="selectedTab" mat-align-tabs="center">
          <mat-tab label="Pending">
            <button (click)="updateDeliveryStatus('pending')" class="btn btn-outline-primary btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Pending
            </button>
          </mat-tab>

          <mat-tab label="Confirmed">
            <button (click)="updateDeliveryStatus('confirmed')" class="btn btn-outline-warning btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Confirmed
            </button>
            <button (click)="updateDeliveryStatusWithCod()" class="btn btn-outline-primary btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Confirm With COD
            </button>
          </mat-tab>

          <mat-tab label="Dispatched">
            <button (click)="updateDeliveryStatus('dispatched')" class="btn btn-outline-secondary btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Dispatched
            </button>
          </mat-tab>

          <mat-tab label="Delivered">
            <button (click)="updateDeliveryStatus('delivered')" class="btn btn-outline-success btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Delivered
            </button>
          </mat-tab>

          <mat-tab label="Canceled">
            <button (click)="updateDeliveryStatus('canceled')" class="btn btn-outline-secondary btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Canceled
            </button>
          </mat-tab>

          <mat-tab label="Return">
            <button (click)="updateDeliveryStatus('return')" class="btn btn-outline-danger btn-rounded btnCenter"
                    type="button"><i class="fa fa-check"></i> Return
            </button>
          </mat-tab>

          <mat-tab label="Warranty Claim">
            <button (click)="warrantyClaim()" class="btn btn-outline-danger btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i> Warranty Claim
            </button>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div *ngIf="previousOrdersArray.length>0" class="card">
      <div class="card-body">
        <h4 class="card-title">Previous Orders</h4>
        <ul class="timeline timeline-left">
          <li *ngFor="let indexElement of previousOrdersArray" class="timeline-inverted timeline-item">
            <div class="timeline-badge success nameIcon">{{indexElement.COD}}</div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h3 class="timeline-title usersName">Delivery Status : <span
                  class="label {{getLabelType(indexElement.deliveryStatus)}}">{{indexElement.deliveryStatus}}</span>
                  ({{indexElement.COD}})
                </h3>
                <h5
                  class="timeline-title usersName">{{formatDate(indexElement.date) + " | " + indexElement.customerName}}</h5>
                <p>
                  <small class="text-muted">
                    <i class="fa fa-clock-o"></i> Last Updated Agent Name : {{indexElement.agent}}<br/>
                    <i class="fa fa-clock-o"></i> Note 1 : {{indexElement.note1}}<br/>
                    <i class="fa fa-clock-o"></i> Note 2 : {{indexElement.note2}}<br/>
                    <i class="fa fa-clock-o"></i> Note 3 : {{indexElement.note3}}<br/>
                  </small>
                </p>
              </div>
              <div class="timeline-body">
                <p *ngIf="doShowPreviousDevices(indexElement.numDevice1)">@Item 1 : {{getItemName(indexElement.device1)}} | Qty : {{indexElement.numDevice1}}</p>
                <p *ngIf="doShowPreviousDevices(indexElement.numDevice2)">@Item 2 : {{getItemName(indexElement.device2)}} | Qty : {{indexElement.numDevice2}}</p>
                <p *ngIf="doShowPreviousDevices(indexElement.numDevice3)">@Item 3 : {{getItemName(indexElement.device3)}} | Qty : {{indexElement.numDevice3}}</p>
                <p *ngIf="doShowPreviousDevices(indexElement.numDevice4)">@Item 4 : {{getItemName(indexElement.device4)}} | Qty : {{indexElement.numDevice4}}</p>
                <p *ngIf="doShowPreviousDevices(indexElement.numDevice5)">@Item 5 : {{getItemName(indexElement.device5)}} | Qty : {{indexElement.numDevice5}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>



    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Print Address</h4>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <button (click)="openSingleAddressPrint()" class="btn btn-success btn-rounded btnCenter" type="button"><i
                class="fa fa-home"></i> Print Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Client Area</h4>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <button (click)="openClientViewForUser()" class="btn btn-secondary btn-rounded btnCenter" type="button"><i
                class="fa fa-eye"></i> View Client Area
              </button>
              <input value="https://track.buyzone.lk/?cod={{cod_edit}}" disabled style="text-align: center;">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel (opened)="setNoteId(0)" [expanded]="noteId === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-notebook"></i> &nbsp; Note 1 &nbsp;&nbsp;<span class="noteContent">({{note1Edit}}
                )</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <input [(ngModel)]="note1Edit" class="form-control" ngModel type="text"
                     value="{{note1Edit}}"/>
            </div>
            <button (click)="updateNote('1')" class="btn btn-primary btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i>&nbsp;&nbsp; Update Note 1
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setNoteId(1)" [expanded]="noteId === 1" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-notebook"></i>&nbsp;&nbsp; Note 2 &nbsp;&nbsp;<span class="noteContent">({{note2Edit}}
                )</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <input [(ngModel)]="note2Edit" class="form-control" ngModel type="text"
                     value="{{note2Edit}}"/>
            </div>
            <button (click)="updateNote('2')" class="btn btn-primary btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i>&nbsp;&nbsp; Update Note 2
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setNoteId(2)" [expanded]="noteId === 2" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-notebook"></i>&nbsp;&nbsp; Note 3 &nbsp;&nbsp;<span class="noteContent">({{note3Edit}}
                )</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <input [(ngModel)]="note3Edit" class="form-control" ngModel type="text"
                     value="{{note3Edit}}"/>
            </div>
            <button (click)="updateNote('3')" class="btn btn-primary btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i>&nbsp;&nbsp; Update Note 3
            </button>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel (opened)="setReturnBlockId(0)" [expanded]="returnBlockId === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-notebook"></i> &nbsp; Reason to Return &nbsp;&nbsp;<span
                class="noteContent">({{reasonToReturnOrder}}
                )</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <input [(ngModel)]="reasonToReturnOrder" class="form-control" ngModel type="text"
                     value="{{reasonToReturnOrder}}"/>
            </div>
            <button (click)="returnOrderBlock('1')" class="btn btn-primary btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i>&nbsp;&nbsp; Update Reason to Return
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setReturnBlockId(1)" [expanded]="returnBlockId === 1" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-notebook"></i>&nbsp;&nbsp; Mistake By &nbsp;&nbsp;<span
                class="noteContent">({{mistakeBy}}
                )</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <select [(ngModel)]="mistakeBy" class="form-control" ngModel value="{{mistakeBy}}">
                <option value="Agent">Agent</option>
                <option value="Courier">Courier</option>
                <option value="Customer">Customer</option>
              </select>
            </div>
            <button (click)="returnOrderBlock('2')" class="btn btn-primary btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i>&nbsp;&nbsp; Update Mistake By
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setReturnBlockId(2)" [expanded]="returnBlockId === 2" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-notebook"></i>&nbsp;&nbsp; Authorize By &nbsp;&nbsp;<span
                class="noteContent">({{AuthorizedBy}}
                )</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <button (click)="returnOrderBlock('3')" class="btn btn-primary btn-rounded btnCenter" type="button"><i
              class="fa fa-check"></i>&nbsp;&nbsp; Authorize Return
            </button>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Print Bill</h4>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <button (click)="getInvoice()" class="btn btn-primary btn-rounded btnCenter" type="button"><i
                class="fa fa-check"></i> Display Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Order Timeline</h4>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <ul class="timeline timeline-left">
                  <li *ngFor="let indexElement of timeLineDataArray" class="timeline-inverted timeline-item">
                    <div class="timeline-badge success nameIcon">{{getTheFirstCharacter(indexElement.empName)}}</div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h5 class="timeline-title usersName">{{indexElement.empName}}</h5>
                        <p>
                          <small class="text-muted"> <i class="fa fa-clock-o"></i>
                            @ {{formatTime(indexElement.dateAndTime)}} via Web</small>
                        </p>
                      </div>
                      <div class="timeline-body">
                        <p>{{indexElement.jobComment}}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
