<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  [attr.data-boxed-layout]="options.boxed"
  [attr.data-header-position]="options.headerpos"
  [attr.data-layout]="options.layout"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-theme]="options.theme"
  [dir]="options.dir"
  [ngClass]="{'show-sidebar': showMobileMenu}"
  data-layout="vertical"
  data-sidebartype="full"
  id="main-wrapper"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header [attr.data-navbarbg]="options.navbarbg" class="topbar">
    <nav [ngClass]="(options.navbarbg=='skin6')?'navbar-light':'navbar-dark'"
         class="d-flex top-navbar navbar-expand-md">
      <div [attr.data-logobg]="options.logobg" [ngClass]="(expandLogo)?'expand-logo':''" class="navbar-header">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none"
           href="javascript:void(0)">
          <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/src">
          <!-- Logo icon -->
          <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img alt="homepage" class="dark-logo" src="../../../../assets/images/logo-icon.png"/>
            <!-- Light Logo icon -->
            <img alt="homepage" class="light-logo" src="../../../../assets/images/logo-light-icon.png"/>
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
                        <!-- dark Logo text -->
                        <img alt="homepage" class="dark-logo" src="../../../../assets/images/logo-text.png"/>
            <!-- Light Logo text -->
                        <img alt="homepage" class="light-logo" src="../../../../assets/images/logo-light-text.png"/>
                    </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarSupportedContent"
          class="topbartoggler d-block d-md-none waves-effect waves-light"
          data-target="#navbarSupportedContent"
          href="javascript:void(0)"
        >
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div [attr.data-navbarbg]="options.navbarbg" [ngbCollapse]="!isCollapsed" class="navbar-collapse collapse"
           id="navbarSupportedContent">
        <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside (mouseout)="Logo()" (mouseover)="Logo()" [attr.data-sidebarbg]="options.sidebarbg" class="left-sidebar">
    <!-- Sidebar scroll-->
    <div [perfectScrollbar]="config" class="scroll-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <app-breadcrumb></app-breadcrumb>
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer  fixed-bottom text-center">
      All Rights Reserved. Designed and Developed by Sasanka Withanage.
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <aside [ngClass]="{'show-service-panel': showSettings}" class="customizer">
    <a (click)="showSettings = !showSettings" class="service-panel-toggle" href="javascript:void(0)">
      <i class="fa fa-spin fa-cog"></i>
    </a>
    <div [perfectScrollbar]="config" class="customizer-body custom-pills">
      <ngb-tabset [justify]="tabStatus" type="pills">
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="mdi mdi-wrench font-20"></i>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-15 border-bottom">
              <!-- Sidebar -->
              <h5 class="font-medium m-b-20 m-t-10">Layout Settings</h5>
              <div class="custom-control custom-checkbox m-t-10">
                <input (change)="changeTheme();" [attr.checked]="(options.theme=='dark') ? 'checked' : null"
                       class="custom-control-input" id="theme-view" name="theme-view" type="checkbox"/>
                <label class="custom-control-label" for="theme-view">Dark Theme</label>
              </div>
              <div class="custom-control custom-checkbox m-t-10">
                <input (change)="changeFixedSidebar()" [attr.checked]="(options.sidebarpos=='fixed') ? 'checked' : null"
                       class="custom-control-input" id="sidebar-position" name="sidebar-position" type="checkbox"/>
                <label class="custom-control-label" for="sidebar-position">Fixed Sidebar</label>
              </div>
              <div class="custom-control custom-checkbox m-t-10">
                <input (change)="changeHeader();" [attr.checked]="(options.headerpos=='fixed') ? 'checked' : null"
                       class="custom-control-input" id="header-position" name="header-position" type="checkbox"/>
                <label class="custom-control-label" for="header-position">Fixed Header</label>
              </div>
              <div class="custom-control custom-checkbox m-t-10">
                <input (change)="changeBoxedLayout()" [attr.checked]="(options.boxed=='boxed') ? 'checked' : null"
                       class="custom-control-input" id="boxed-layout" name="boxed-layout" type="checkbox"/>
                <label class="custom-control-label" for="boxed-layout">Boxed Layout</label>
              </div>
              <div class="custom-control custom-checkbox m-t-10">
                <input (change)="changeRtl();" [attr.checked]="(options.dir=='rtl') ? 'checked' : null"
                       class="custom-control-input" id="rtl-layout" name="rtl-layout" type="checkbox"/>
                <label class="custom-control-label" for="rtl-layout">RTL</label>
              </div>
            </div>
            <div class="p-15 border-bottom">
              <!-- Sidebar -->
              <h5 class="font-medium m-b-20 m-t-10">Sidebar Types</h5>
              <div class="custom-control custom-radio m-t-10">
                <input (change)="changeSideBarType('mini-sidebar')" [(ngModel)]="options.sidebartype"
                       class="custom-control-input" id="minisidebar" name="sidebar" type="radio" value="mini-sidebar"/>
                <label class="custom-control-label" for="minisidebar">Mini Sidebar</label>
              </div>
              <div class="custom-control custom-radio m-t-10">
                <input (change)="changeSideBarType('iconbar')" [(ngModel)]="options.sidebartype"
                       class="custom-control-input" id="iconsidebar" name="sidebar" type="radio" value="iconbar"/>
                <label class="custom-control-label" for="iconsidebar">Icon Sidebar</label>
              </div>
              <div class="custom-control custom-radio m-t-10">
                <input (change)="changeSideBarType('overlay')" [(ngModel)]="options.sidebartype"
                       class="custom-control-input" id="overlaysidebar" name="sidebar" type="radio" value="overlay"/>
                <label class="custom-control-label" for="overlaysidebar">Overlay Sidebar</label>
              </div>
              <div class="custom-control custom-radio m-t-10">
                <input (change)="changeSideBarType('full')" [(ngModel)]="options.sidebartype"
                       class="custom-control-input" id="fullsidebar" name="sidebar" type="radio" value="full"/>
                <label class="custom-control-label" for="fullsidebar">Full Sidebar</label>
              </div>
            </div>
            <div class="p-15 border-bottom">
              <!-- Logo BG -->
              <h5 class="font-medium m-b-20 m-t-10">Border Colors</h5>
              <ul class="theme-color">
                <li class="theme-item">
                  <a (click)="changeNavBarColors( 'skin1')" class="theme-link" data-logobg="skin1"
                     href="javascript:void(0)"></a>
                </li>
                <li class="theme-item">
                  <a (click)="changeNavBarColors('skin2')" class="theme-link" data-logobg="skin2"
                     href="javascript:void(0)"></a>
                </li>
                <li class="theme-item">
                  <a (click)="changeNavBarColors('skin3')" class="theme-link" data-logobg="skin3"
                     href="javascript:void(0)"></a>
                </li>
                <li class="theme-item">
                  <a (click)="changeNavBarColors('skin4')" class="theme-link" data-logobg="skin4"
                     href="javascript:void(0)"></a>
                </li>
                <li class="theme-item">
                  <a (click)="changeNavBarColors('skin5')" class="theme-link" data-logobg="skin5"
                     href="javascript:void(0)"></a>
                </li>
                <li class="theme-item">
                  <a (click)="changeNavBarColors('skin6')" class="theme-link" data-logobg="skin6"
                     href="javascript:void(0)"></a>
                </li>
              </ul>
              <!-- Logo BG -->
            </div>
          </ng-template>
        </ngb-tab>

        <!--        <ngb-tab>-->
        <!--          <ng-template ngbTabTitle>-->
        <!--            <i class="mdi mdi-message-reply font-20"></i>-->
        <!--          </ng-template>-->
        <!--          <ng-template ngbTabContent>-->
        <!--            <ul class="mailbox list-style-none m-t-20">-->
        <!--              <li>-->
        <!--                <div [perfectScrollbar]="config" class="message-center chat-scroll">-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="1" href="#" id="chat_user_1">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                                            <span class="profile-status online pull-right" data-status="online"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Chris Evans</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:30 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="1" href="#" id="chat_user_1">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                                            <span class="profile-status online pull-right" data-status="online"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Chris Evans</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:30 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="1" href="#" id="chat_user_1">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                                            <span class="profile-status online pull-right" data-status="online"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Chris Evans</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:30 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="1" href="#" id="chat_user_1">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                                            <span class="profile-status online pull-right" data-status="online"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Chris Evans</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:30 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="2" href="#" id="chat_user_2">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/2.jpg" />-->
        <!--                                            <span class="profile-status busy pull-right" data-status="busy"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Ray Hudson</h5>-->
        <!--                                            <span class="mail-desc">I've sung a song! See you at</span>-->
        <!--                                            <span class="time">9:10 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="3" href="#" id="chat_user_3">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/3.jpg" />-->
        <!--                                            <span class="profile-status away pull-right" data-status="away"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Lb James</h5>-->
        <!--                                            <span class="mail-desc">I am a singer!</span>-->
        <!--                                            <span class="time">9:08 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="4" href="#" id="chat_user_4">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/4.jpg" />-->
        <!--                                            <span class="profile-status offline pull-right" data-status="offline"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Don Andres</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:02 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="5" href="#" id="chat_user_5">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                                            <span class="profile-status online pull-right" data-status="online"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Chris Evans</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:30 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="6" href="#" id="chat_user_6">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/2.jpg" />-->
        <!--                                            <span class="profile-status busy pull-right" data-status="busy"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Ray Hudson</h5>-->
        <!--                                            <span class="mail-desc">I've sung a song! See you at</span>-->
        <!--                                            <span class="time">9:10 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="7" href="#" id="chat_user_7">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/3.jpg" />-->
        <!--                                            <span class="profile-status away pull-right" data-status="away"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Lb James</h5>-->
        <!--                                            <span class="mail-desc">I am a singer!</span>-->
        <!--                                            <span class="time">9:08 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="8" href="#" id="chat_user_8">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/4.jpg" />-->
        <!--                                            <span class="profile-status offline pull-right" data-status="offline"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Don Andres</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:02 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="9" href="#" id="chat_user_9">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                                            <span class="profile-status online pull-right" data-status="online"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Chris Evans</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:30 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="10" href="#" id="chat_user_10">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/2.jpg" />-->
        <!--                                            <span class="profile-status busy pull-right" data-status="busy"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Ray Hudson</h5>-->
        <!--                                            <span class="mail-desc">I've sung a song! See you at</span>-->
        <!--                                            <span class="time">9:10 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="11" href="#" id="chat_user_11">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/3.jpg" />-->
        <!--                                            <span class="profile-status away pull-right" data-status="away"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Lb James</h5>-->
        <!--                                            <span class="mail-desc">I am a singer!</span>-->
        <!--                                            <span class="time">9:08 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                  &lt;!&ndash; Message &ndash;&gt;-->
        <!--                  <a class="message-item" data-user-id="12" href="#" id="chat_user_12">-->
        <!--                                        <span class="user-img">-->
        <!--                                            <img alt="user" class="rounded-circle" src="../../../../assets/images/users/4.jpg" />-->
        <!--                                            <span class="profile-status offline pull-right" data-status="offline"></span>-->
        <!--                                        </span>-->
        <!--                    <span class="mail-contnet">-->
        <!--                                            <h5>Don Andres</h5>-->
        <!--                                            <span class="mail-desc">Just see the my admin!</span>-->
        <!--                                            <span class="time">9:02 AM</span>-->
        <!--                                        </span>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </ng-template>-->
        <!--        </ngb-tab>-->


        <!--        <ngb-tab>-->
        <!--          <a (click)="updateUserActivities()">-->
        <!--            <ng-template ngbTabTitle >-->
        <!--              <i class="mdi mdi-star-circle font-20"></i>-->
        <!--            </ng-template>-->
        <!--          </a>-->
        <!--          <ng-template ngbTabContent>-->
        <!--            <div class="p-15">-->
        <!--              <h6 class="m-t-20 m-b-20">You'r Latest Activities</h6>-->

        <!--              <div class="steamline">-->

        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left bg-success">-->
        <!--                    <i class="ti-user"></i>-->
        <!--                  </div>-->
        <!--                  <div class="sl-right">-->
        <!--                    <div class="font-medium">-->
        <!--                      Meeting today-->
        <!--                      <span class="sl-date"> 5pm</span>-->
        <!--                    </div>-->
        <!--                    <div class="desc">you can write anything</div>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left bg-info">-->
        <!--                    <i class="fas fa-image"></i>-->
        <!--                  </div>-->
        <!--                  <div class="sl-right">-->
        <!--                    <div class="font-medium">Send documents to Clark</div>-->
        <!--                    <div class="desc">Lorem Ipsum is simply</div>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left">-->
        <!--                    <img alt="user" class="rounded-circle" src="../../../../assets/images/users/2.jpg" />-->
        <!--                  </div>-->

        <!--                  <div class="sl-right">-->
        <!--                    <div class="font-medium">-->
        <!--                      Go to the Doctor-->
        <!--                      <span class="sl-date">5 minutes ago</span>-->
        <!--                    </div>-->
        <!--                    <div class="desc">Contrary to popular belief</div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left">-->
        <!--                    <img alt="user" class="rounded-circle" src="../../../../assets/images/users/1.jpg" />-->
        <!--                  </div>-->

        <!--                  <div class="sl-right">-->
        <!--                    <div>-->
        <!--                      <a href="javascript:void(0)">Stephen</a>-->
        <!--                      <span class="sl-date">5 minutes ago</span>-->
        <!--                    </div>-->
        <!--                    <div class="desc">Approve meeting with tiger</div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left bg-primary">-->
        <!--                    <i class="ti-user"></i>-->
        <!--                  </div>-->
        <!--                  <div class="sl-right">-->
        <!--                    <div class="font-medium">-->
        <!--                      Meeting today-->
        <!--                      <span class="sl-date"> 5pm</span>-->
        <!--                    </div>-->
        <!--                    <div class="desc">you can write anything</div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left bg-info">-->
        <!--                    <i class="fas fa-image"></i>-->
        <!--                  </div>-->
        <!--                  <div class="sl-right">-->
        <!--                    <div class="font-medium">Send documents to Clark</div>-->
        <!--                    <div class="desc">Lorem Ipsum is simply</div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left">-->
        <!--                    <img alt="user" class="rounded-circle" src="../../../../assets/images/users/4.jpg" />-->
        <!--                  </div>-->

        <!--                  <div class="sl-right">-->
        <!--                    <div class="font-medium">-->
        <!--                      Go to the Doctor-->
        <!--                      <span class="sl-date">5 minutes ago</span>-->
        <!--                    </div>-->
        <!--                    <div class="desc">Contrary to popular belief</div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="sl-item">-->
        <!--                  <div class="sl-left">-->
        <!--                    <img alt="user" class="rounded-circle" src="../../../../assets/images/users/6.jpg" />-->
        <!--                  </div>-->

        <!--                  <div class="sl-right">-->
        <!--                    <div>-->
        <!--                      <a href="javascript:void(0)">Stephen</a>-->
        <!--                      <span class="sl-date">5 minutes ago</span>-->
        <!--                    </div>-->
        <!--                    <div class="desc">Approve meeting with tiger</div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </ng-template>-->
        <!--        </ngb-tab>-->
      </ngb-tabset>
    </div>
  </aside>
  <div class="chat-windows"></div>
</div>
