import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AgmCoreModule} from '@agm/core';

import {FullComponent} from './Admin/layouts/full/full.component';
import {FullComponentUSER} from './User/layouts/full/full.component';
import {BlankComponent} from './Admin/layouts/blank/blank.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {SidebarComponent} from './Admin/shared/sidebar/sidebar.component';
import {BreadcrumbComponent} from './Admin/shared/breadcrumb/breadcrumb.component';

import {Approutes} from './app-routing.module';
import {AppComponent} from './app.component';
import {SpinnerComponent} from './Admin/shared/spinner.component';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NotifierModule} from "angular-notifier";
import {AddressStickerWithDateComponent} from './Common/address-sticker-with-date/address-sticker-with-date.component';
import {SidebarComponentUser} from "./User/shared/sidebar/sidebar.component";
import {BlankComponentUSER} from "./User/layouts/blank/blank.component";
import {BlankComponentAll} from "./blankLayout/blank.component";
import {DashboardModuleUser} from "./User/dashboard-module-user.module";
import {DashboardModule} from "./Admin/dashboard.module";
import {NbThemeModule} from "@nebular/theme";
import {NgxBarcodeModule} from "ngx-barcode";
import { CodUpdateComponent } from './Admin/cod-update/cod-update.component';
import {AuthGuard} from './auth.guard';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponentUSER,
    FullComponent,
    BlankComponent,
    BlankComponentUSER,
    BlankComponentAll,
    BreadcrumbComponent,
    SidebarComponent,
    SidebarComponentUser,
    AddressStickerWithDateComponent,
    CodUpdateComponent,
  ],
  imports: [
    NotifierModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes, {useHash: false}),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBUb3jDWJQ28vDJhuQZxkC0NXr_zycm8D0'}),
    DashboardModuleUser,
    DashboardModule,
    NbThemeModule.forRoot(),
    NgxBarcodeModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
