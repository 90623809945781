<div class="row">
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <label class="form-control-label">Delivery Type</label>
                <ng-autocomplete
                  (selected)="selectedDeliveryType($event)"
                  [data]="allUsers"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="currentSelectedDeliveryTypeID"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
              <div class="col-lg-4">
                <label class="form-control-label">Date</label>
                <input [(ngModel)]="selectedDate" class="form-control" type="date" value="{{selectedDate}}" (change)="selectedDateUpdate($event)" />
              </div>
              <div class="col-lg-4">
                <label class="form-control-label">Quick Excel Download</label>
                <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                        type="button">
                  Download As Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body mainBlockColor warrenty">
                <br />
                <h6 class="orderDetails">Total Count : {{totalOrders}}  |
                  Pending : {{dispatchedOrdersCount}}  |
                  Delivered : {{deliveredOrdersCount}}  |
                  Return : {{returnedOrdersCount}}  |
                  Delivered : {{getRoundNumber(deliveredOrdersCount / totalOrders * 100)}}%</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body" *ngIf="clientList.length > 0">
                <label class="form-control-label">User's List</label>
                <p *ngFor="let elements of clientList; index as i">{{(i+1)}}. {{elements}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary" data-toggle="modal" style="display: none;" data-target="#updateValueToggle" id="updateValueToggles"></button>

        <!-- Modal -->
        <div class="modal fade" id="updateValueToggle" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Update Checking Details</h5>
                <button type="button" class="close" id="closeButton" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label class="form-control-label">Current Delivery Status</label>
                <select [(ngModel)]="clientStatus" class="form-control" type="text" value="{{clientStatus}}">
                  <option value="dispatched">Dispatched</option>
                  <option value="delivered">Delivered</option>
                  <option value="return">Returned</option>
                </select>
                <br />
                <label class="form-control-label">Description</label>
                <input [(ngModel)]="clientComment" class="form-control" min="0" ngModel type="text" value="{{clientComment}}" />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="updateDetails()">Update Details</button>
              </div>
            </div>
          </div>
        </div>


        <!-- Display order tracking details -->
        <button type="button" class="btn btn-primary" data-toggle="modal" style="display: none;" data-target="#trackingDetails" id="trackingDetailsOpen"></button>

        <!-- Modal -->
        <div class="modal fade" id="trackingDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="table-responsive" style="max-height: 800px;">
                  <table class="table table-hover table-bordered table-striped">
                    <tr>
                      <th>Date</th>
                      <th>City</th>
                      <th>Status</th>
                    </tr>
                    <tr *ngFor="let element of prontoTrackingDetails">
                      <td>{{dateFormatter(element.STATUS_DATE)}} {{timeFormatter(element.STATUS_DATE)}}</td>
                      <td>{{element.PRONTO_HUB}}</td>
                      <td>{{element.STATUS}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12">
          <div class="table-responsive" style="max-height: 800px;" id="tableOfData">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Change</th>
                <th scope="col">Pronto</th>
                <th scope="col">COD</th>
                <th scope="col">City</th>
                <th scope="col">customerName</th>
                <th scope="col">phoneNumber</th>
                <th scope="col">fixedLine</th>
                <th scope="col">clientStatus</th>
                <th scope="col">CurrentStatus</th>
                <th scope="col">{{getDate(1)}}</th>
                <th scope="col">{{getDate(2)}}</th>
                <th scope="col">{{getDate(3)}}</th>
                <th scope="col">{{getDate(4)}}</th>
                <th scope="col">{{getDate(5)}}</th>
                <th scope="col">{{getDate(6)}}</th>
                <th scope="col">{{getDate(7)}}</th>
                <th scope="col">{{getDate(8)}}</th>
                <th scope="col">{{getDate(9)}}</th>
                <th scope="col">{{getDate(10)}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let indexElement of rows" [style.background-color]="getColor(indexElement.clientStatus)">
                <td>
                  <a (click)="updateOrderUserDetails(indexElement.orderNumber, indexElement.clientStatus, indexElement['dStatusNote'+getDifferentTime()])"> <i class="fas fa-edit pointer"></i></a>
                </td>
                <td>
                  <a (click)="getCodDetails(indexElement.COD)"> <i class="fas fa-eye pointer"></i></a>
                </td>
                <td>{{indexElement.COD}}</td>
                <td>{{indexElement.city}}</td>
                <td>{{indexElement.customerName}}</td>
                <td>{{indexElement.phoneNumber}}</td>
                <td>{{indexElement.fixedLine}}</td>
                <td>{{indexElement.deliveryStatus}}</td>
                <td>{{indexElement.clientStatus}}</td>
                <td>{{indexElement.dStatusNote1}}</td>
                <td>{{indexElement.dStatusNote2}}</td>
                <td>{{indexElement.dStatusNote3}}</td>
                <td>{{indexElement.dStatusNote4}}</td>
                <td>{{indexElement.dStatusNote5}}</td>
                <td>{{indexElement.dStatusNote6}}</td>
                <td>{{indexElement.dStatusNote7}}</td>
                <td>{{indexElement.dStatusNote8}}</td>
                <td>{{indexElement.dStatusNote9}}</td>
                <td>{{indexElement.dStatusNote10}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
