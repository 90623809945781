import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ResoponseObject} from './orders.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  truncateValues(string: any, number:any) {
    if (string.length > number) {
      return string.slice(0, number) + "...";
    } else {
      return string;
    }
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  isNull(string) {
    return string === "" || string === undefined  || string === 'undefined' || string === null || string === "null";
  }

  priceFormat(value){
    return value.toFixed(2)
  }

  showSpin = () => {
    sessionStorage.setItem("loader","true")
  }

  hideSpin = () => {
    sessionStorage.setItem("loader","false")
  }
}
