import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class BlankComponentUSER implements OnInit {

  constructor(public router: Router) {
  }

  ngOnInit(): void {

  }
}
