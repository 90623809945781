<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Device Id</th>
                <th scope="col">Device Name</th>
                <th scope="col">Purchased Price</th>
                <th scope="col">Sold Price</th>
                <th scope="col">Envelope Size</th>
              </tr>
              </thead>
              <tbody>
              <tr
                (click)="updateItemToBlock(indexElement.deviceId,indexElement.name,indexElement.purchasedPrice,indexElement.soldPrice,indexElement.envelope)"
                *ngFor="let indexElement of rows">
                <td>{{indexElement.deviceId}}</td>
                <td>{{indexElement.name}}</td>
                <td>{{indexElement.purchasedPrice}}</td>
                <td>{{indexElement.soldPrice}}</td>
                <td>{{indexElement.envelope}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card">
      <div class="card-body">
        <mat-accordion class="example-headers-align">

          <mat-expansion-panel (opened)="setAccordionId(1)" [expanded]="noteId === 1" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-plus"></i>&nbsp;&nbsp; Add Item</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Item Name</label>
              <input [(ngModel)]="name" class="form-control" ngModel type="text" value="{{name}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Purchase Price</label>
              <input [(ngModel)]="purchasedPrice" class="form-control" min="0" ngModel type="number"
                     value="{{purchasedPrice}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Sold Price</label>
              <input [(ngModel)]="soldPrice" class="form-control" min="0" ngModel type="number" value="{{soldPrice}}"/>
            </div>

            <div class="col-lg-12">
              <button (click)="addItem()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                      type="button">
                Add Item
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(0)" [expanded]="noteId === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-refresh"></i>&nbsp;&nbsp; Item Update</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Item ID</label>
              <input [(ngModel)]="deviceId" class="form-control" disabled ngModel type="text" value="{{deviceId}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Item Name</label>
              <input [(ngModel)]="name" class="form-control" ngModel type="text" value="{{name}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Purchase Price</label>
              <input [(ngModel)]="purchasedPrice" class="form-control" min="0" ngModel type="number"
                     value="{{purchasedPrice}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Sold Price</label>
              <input [(ngModel)]="soldPrice" class="form-control" min="0" ngModel type="number" value="{{soldPrice}}"/>
            </div>

            <div class="col-6 m-t-10 m-b-10">
              <label class="form-control-label">Envelope Size</label>
              <select [(ngModel)]="envelope" class="form-control" type="text" value="{{envelope}}">
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </div>

            <div class="col-lg-12">
              <button (click)="updateItem()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                      type="button">
                Update Item
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(2)" [expanded]="noteId === 2" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-cloud-download"></i>&nbsp;&nbsp; Download Excel</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-lg-12">
              <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                Download As Excel
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
