<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" id="bootstrap-css" rel="stylesheet"/>
<div id="invoice">
  <div class="invoice overflow-auto">
    <div style="min-width: 600px;">
      <header>
        <div class="row">
          <div class="col">
            <img class="invoiceLogo" data-holder-rendered="true" src="assets/images/logo/wide-logo-black.png"/>
          </div>
          <div class="col company-details">
            <h2 class="name">
              BuyZone.lk
            </h2>
            <div>
              174/5,<br/>
              Balika Nivasa Rd,<br/>
              Pannipitiya,<br/>
              10230
            </div>
            <div>070 670 0800</div>
            <div>info@buyzone.lk</div>
          </div>
        </div>
      </header>
      <main>
        <div class="row contacts">
          <div class="col invoice-to">
            <div class="text-gray-light">INVOICE TO:</div>
            <h2 class="to">{{clientName}} (NIC : {{nicNumber}})</h2>
            <div class="address">{{addressNumber}},</div>
            <div class="address">{{firstLine}},</div>
            <div class="address">{{secondLine}},</div>
            <div class="address">{{city}}.</div>
            <div class="email">{{phoneNumber}} / {{telephone}}</div>
          </div>
          <div class="col invoice-details">
            <h1 class="invoice-id">{{codNumber}}</h1>
            <div class="date">Date of Invoice: {{todayDate}}</div>
          </div>
        </div>
        <table border="0" cellpadding="0" cellspacing="0">
          <thead>
          <tr>
            <th>#</th>
            <th class="text-left">DESCRIPTION</th>
            <th class="text-right">ITEM PRICE</th>
            <th class="text-right">QTY</th>
            <th class="text-right">TOTAL</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="item1 != '0'">
            <td class="no">01</td>
            <td class="text-left"><h3>{{item1}}</h3></td>
            <td class="unit">{{unitPrice1}}.00 Lkr</td>
            <td class="qty">{{qty1}}</td>
            <td class="total">{{unitTotal1}}.00 Lkr</td>
          </tr>

          <tr *ngIf="item2 != '0'">
            <td class="no">02</td>
            <td class="text-left"><h3>{{item2}}</h3></td>
            <td class="unit">{{unitPrice2}}.00 Lkr</td>
            <td class="qty">{{qty2}}</td>
            <td class="total">{{unitTotal2}}.00 Lkr</td>
          </tr>

          <tr *ngIf="item3 != '0'">
            <td class="no">03</td>
            <td class="text-left"><h3>{{item3}}</h3></td>
            <td class="unit">{{unitPrice3}}.00 Lkr</td>
            <td class="qty">{{qty3}}</td>
            <td class="total">{{unitTotal3}}.00 Lkr</td>
          </tr>

          <tr *ngIf="item4 != '0'">
            <td class="no">04</td>
            <td class="text-left"><h3>{{item4}}</h3></td>
            <td class="unit">{{unitPrice4}}.00 Lkr</td>
            <td class="qty">{{qty4}}</td>
            <td class="total">{{unitTotal4}}.00 Lkr</td>
          </tr>

          <tr *ngIf="item5 != '0'">
            <td class="no">05</td>
            <td class="text-left"><h3>{{item5}}</h3></td>
            <td class="unit">{{unitPrice5}}.00 Lkr</td>
            <td class="qty">{{qty5}}</td>
            <td class="total">{{unitTotal5}}.00 Lkr</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">GRAND TOTAL</td>
            <td>{{total}}.00 LKR</td>
          </tr>
          </tfoot>
        </table>
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="thanks">Thank you!</div>
        <div class="notices">
          <div>NOTICE:</div>
          <div class="notice">180 days software warranty / 30 days hardware warranty</div>
          <div class="notice">Invoice is mandatory for the warranty claim**</div>
        </div>
      </main>
    </div>
    <div></div>
  </div>
</div>
