// @ts-ignore
import {Component, OnInit} from '@angular/core';
import {ServiceService} from "../../../service.service";
// @ts-ignore
import {ActivatedRoute, Router} from "@angular/router";
// @ts-ignore
import {FormControl} from '@angular/forms';
// @ts-ignore
import {Observable} from 'rxjs';
// @ts-ignore
import {map, startWith} from 'rxjs/operators';
// @ts-ignore
import {NotifierService} from "angular-notifier";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-update-order',
  templateUrl: './update-order.component.html',
  styleUrls: ['./update-order.component.css']
})
export class UpdateOrderComponent implements OnInit {
  agentEmail: any = 'NotFound';
  dataToSendToServer: any = [];
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  timeLineDataArray: any;
  currentCodNumber: any = 'COD Number';
  currentDeliveryStatus: any = 'Delivery Status';
  customerName: any = 'Customer Name';
  currentAddress: any = 'Current Address';
  orderPlacedDate: any = 'Ordered Date';
  deliveryDate: any = 'Delivery Date';
  lastEditedBy: any = 'Last Edited By';
  phone: any;
  telephone: any;
  dataArray: any[];
  backgroundColorOfBlock: any = 'canceled';
  device1: any = 'none';
  device2: any = 'none';
  device3: any = 'none';
  device4: any = 'none';
  device5: any = 'none';
  keyword = 'name';
  specialNotes: any;
  numDevice1: any;
  specialNote1: any;
  numDevice2: any;
  specialNote2: any;
  numDevice3: any;
  specialNote3: any;
  numDevice4: any;
  specialNote4: any;
  numDevice5: any;
  specialNote5: any;
  cod_edit: any;
  name_edit: any;
  phone_edit: any;
  telephone_edit: any;
  nic_edit: any;
  deliveryDate_edit: any;
  addressNo_edit: any;
  firstLine_edit: any;
  secondLine_edit: any;
  city_edit: any = 'City';
  specialNotes_edit: any;
  firstItem_edit: any;
  firstItemCount_edit: any;
  firstItemNote_edit: any;
  secondItem_edit: any;
  secondItemCount_edit: any;
  secondItemNote_edit: any;
  thirdItem_edit: any;
  thirdItemCount_edit: any;
  thirdItemNote_edit: any;
  fourthItem_edit: any;
  fourthItemCount_edit: any;
  fourthItemNote_edit: any;
  fifthItem_edit: any;
  fifthItemCount_edit: any;
  fifthItemNote_edit: any;
  orderId: any;
  phoneDetailsJsonArray = [];
  cityDataListJson = [];
  firstItem_name_edit: any;
  secondItem_name_edit: any;
  thirdItem_name_edit: any;
  fourthItem_name_edit: any;
  fifthItem_name_edit: any;
  deliveryTypeArray = [];
  deliveryName: any = '';
  deliveryPrice: any = 0;
  item1PriceSub: any;
  item2PriceSub: any;
  item3PriceSub: any;
  item4PriceSub: any;
  item5PriceSub: any;
  totalCost: any = 0;
  totalPrice: any = 0;
  userArray: any = [];
  deliveryId: any;
  deliveryStatus: any;
  selectedTab: any;
  currentOrderDate: any;
  step = 25;
  noteId = 25;
  returnBlockId = 25;
  note1Edit: any;
  note2Edit: any;
  note3Edit: any;
  orderType: any;
  warrantyTotalPrice: any = 0;
  totalPriceTemp: any = 0;
  totalCostTemp: any = 0;
  reasonToReturnOrder: any;
  mistakeBy: any;
  AuthorizedBy: any;
  envelope: any;
  warrantyApproval:any;
  private codNumber: any;
  private notifier: NotifierService;
  previousOrdersArray: any = [];

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
    this.activatedRoute.queryParams.subscribe(params => {
      this.orderId = params['orderId'];
    });
    const helper = new JwtHelperService();
    this.agentEmail = helper.decodeToken(localStorage.getItem('user_details')).rows[0]["empEmail"];
  }

  setStep(index: number) {
    if (index === 6) {
      this.updatePriceTable();
    }
    this.step = index;
  }

  setNoteId(index: number) {
    this.noteId = index;
  }

  setReturnBlockId(index: number) {
    this.returnBlockId = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit(): void {
    this.doWhenStart();
    this.updateReturnReasonDetails();
  }

  doWhenStart() {
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
        this.getCurrentOrderDetails();
      }
    );

    this.serviceClass.getAllCities().subscribe(
      data => {
        this.cityDataListJson = data;
      }
    );

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.deliveryTypeArray = data;
      }
    );

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    window.scroll(0, 0);

    this.updateTimeLineDetails();


    this.serviceClass.getAllUsers().subscribe(
      data => {
        this.userArray = data;
      }
    );
  }

  getCurrentOrderDetails() {
    this.serviceClass.getSingleOrderDetails(this.orderId).subscribe(
      data => {
        if (data.length > 0) {
          this.dataArray = data;
          this.currentOrderDate = data[0].date;
          this.currentCodNumber = data[0].COD;
          this.currentDeliveryStatus = data[0].deliveryStatus;
          this.customerName = data[0].customerName;
          this.currentAddress = data[0].addressNumber + ', ' + data[0].addressFirstLine + ', ' + data[0].addressSecondLine + ', ' + data[0].city;
          this.orderPlacedDate = this.formatDate(data[0].date);
          this.deliveryDate = this.formatDate(data[0].deliveryDate);
          this.lastEditedBy = data[0].agent;
          this.phone = data[0].phoneNumber;
          this.telephone = data[0].fixedLine;
          this.backgroundColorOfBlock = data[0].deliveryStatus;
          this.deliveryStatus = data[0].deliveryStatus;
          this.deliveryStatus = data[0].deliveryStatus;
          this.device1 = data[0].device1;
          this.device2 = data[0].device2;
          this.device3 = data[0].device3;
          this.device4 = data[0].device4;
          this.device5 = data[0].device5;

          this.specialNotes = data[0].specialNotes;
          this.numDevice1 = data[0].numDevice1;
          this.specialNote1 = data[0].specialNote1;
          this.numDevice2 = data[0].numDevice2;
          this.specialNote2 = data[0].specialNote2;
          this.numDevice3 = data[0].numDevice3;
          this.specialNote3 = data[0].specialNote3;
          this.numDevice4 = data[0].numDevice4;
          this.specialNote4 = data[0].specialNote4;
          this.numDevice5 = data[0].numDevice5;
          this.specialNote5 = data[0].specialNote5;

          this.cod_edit = data[0].COD;
          this.name_edit = data[0].customerName;
          this.phone_edit = data[0].phoneNumber;
          this.telephone_edit = data[0].fixedLine;
          this.nic_edit = data[0].idNumber;
          this.deliveryDate_edit = this.getFormattedDateOfDelivery(data[0].deliveryDate);
          this.addressNo_edit = data[0].addressNumber;
          this.firstLine_edit = data[0].addressFirstLine;
          this.secondLine_edit = data[0].addressSecondLine;
          this.city_edit = data[0].city;
          this.specialNotes_edit = data[0].specialNotes;
          this.firstItem_edit = data[0].device1;
          this.firstItemCount_edit = data[0].numDevice1;
          this.firstItemNote_edit = data[0].specialNote1;
          this.secondItem_edit = data[0].device2;
          this.secondItemCount_edit = data[0].numDevice2;
          this.secondItemNote_edit = data[0].specialNote2;
          this.thirdItem_edit = data[0].device3;
          this.thirdItemCount_edit = data[0].numDevice3;
          this.thirdItemNote_edit = data[0].specialNote3;
          this.fourthItem_edit = data[0].device4;
          this.fourthItemCount_edit = data[0].numDevice4;
          this.fourthItemNote_edit = data[0].specialNote4;
          this.fifthItem_edit = data[0].device5;
          this.fifthItemCount_edit = data[0].numDevice5;
          this.fifthItemNote_edit = data[0].specialNote5;
          this.firstItem_name_edit = this.getItemName(data[0].device1);
          this.secondItem_name_edit = this.getItemName(data[0].device2);
          this.thirdItem_name_edit = this.getItemName(data[0].device3);
          this.fourthItem_name_edit = this.getItemName(data[0].device4);
          this.fifthItem_name_edit = this.getItemName(data[0].device5);
          this.deliveryName = data[0].deliveryType;
          this.deliveryName = this.getDeliveryDetailsName(this.deliveryName);
          this.note1Edit = data[0].note1;
          this.note2Edit = data[0].note2;
          this.note3Edit = data[0].note3;
          this.orderType = data[0].OrderType;
          this.totalPriceTemp = data[0].totalAmount;
          this.totalCostTemp = data[0].totalCost;
          this.warrantyApproval = data[0].warrantyApproval;
          this.envelope = data[0].envelope;

          this.serviceClass.checkPreviousItemsSearch(data[0].phoneNumber, data[0].fixedLine, data[0].fixedLine).subscribe(
            data => {
              if (data.length > 0) {
                this.previousOrdersArray = data;
                for(let x=0; x<this.previousOrdersArray.length;x++){
                  if(this.previousOrdersArray[x].COD === this.cod_edit){
                    this.previousOrdersArray.splice(x,1);
                  }
                }
              }
            }
          );

          this.updatePriceTable();
          this.setProperDeliveryButton();
        } else {
          this.router.navigate(['/admin/main']);
        }
      }
    );
  }

  doShowPreviousDevices(number: any) {
    return Number(number) > 0;
  }

  setProperDeliveryButton() {
    if (this.deliveryStatus == 'pending') {
      this.selectedTab = 0;
    } else if (this.deliveryStatus == 'confirmed') {
      this.selectedTab = 1;
    } else if (this.deliveryStatus == 'dispatched') {
      this.selectedTab = 2;
    } else if (this.deliveryStatus == 'delivered') {
      this.selectedTab = 3;
    } else if (this.deliveryStatus == 'canceled') {
      this.selectedTab = 4;
    } else if (this.deliveryStatus == 'return') {
      this.selectedTab = 5;
    } else {
      this.selectedTab = 6;
    }
  }

  updateDeliveryStatus(newDeliveryStatus: string) {
    this.serviceClass.updateDeliveryStatus(this.orderId, newDeliveryStatus, this.agentEmail).subscribe(
      data => {
        if (data == true) {
          this.showNotification('success', 'Delivery Status Updated to ' + newDeliveryStatus + ' Successfully!');
          this.doWhenStart();
        } else {
          this.showNotification('error', 'Error when updating the delivery Status **');
        }
      }
    );
  }

  warrantyClaim() {
    let warrantyOrderDetails = {
      orderId: this.orderId,
      device1: this.firstItem_edit,
      numDevice1: this.firstItemCount_edit,
      specialNote1: this.firstItemNote_edit,
      device2: this.secondItem_edit,
      numDevice2: this.secondItemCount_edit,
      specialNote2: this.secondItemNote_edit,
      device3: this.thirdItem_edit,
      numDevice3: this.thirdItemCount_edit,
      specialNote3: this.thirdItemNote_edit,
      device4: this.fourthItem_edit,
      numDevice4: this.fourthItemCount_edit,
      specialNote4: this.fourthItemNote_edit,
      device5: this.fifthItem_edit,
      numDevice5: this.fifthItemCount_edit,
      specialNote5: this.fifthItemNote_edit,
      totalCost: this.totalCost,
      totalAmount: this.totalPrice,
      totalProfit: (Number(this.totalPrice) - Number(this.totalCost)),
      customerName: this.name_edit,
      phoneNumber: this.phone_edit,
      fixedLine: this.telephone_edit,
      idNumber: this.nic_edit,
      addressNumber: this.addressNo_edit,
      addressFirstLine: this.firstLine_edit,
      addressSecondLine: this.secondLine_edit,
      city: this.city_edit,
      deliveryDate: this.deliveryDate_edit,
      deliveryType: this.deliveryId,
      deliveryStatus: "warrenty",
      specialNotes: "Warranty Claim | Previous Order Id : " + this.orderId + " | Special Edit : " + this.specialNotes_edit,
      agent: this.agentEmail,
      OrderType: "warrantyClaim",
      envelope: this.envelope
    };

    let todayDate = new Date();
    let warrantyDateCalc;
    this.serviceClass.getDeliveredDateDetails(this.orderId).subscribe(
      data => {
        if (data.length > 0) {
          let deliveredDate = data[0].dateAndTime;
          warrantyDateCalc = new Date(deliveredDate);
          let newMonth = warrantyDateCalc.getMonth() + 3;
          warrantyDateCalc.setMonth(newMonth);

          if(this.warrantyApproval == "approved") {
            if (todayDate < warrantyDateCalc) {
              this.serviceClass.addWarranty(warrantyOrderDetails).subscribe(
                data => {
                  if (data["status"] != false) {
                    this.orderId = data["status"];
                    this.doWhenStart();
                    this.showNotification('success', 'Warranty Order added successfully!');
                    this.showNotification('success', 'Edit warranty order before leave!');
                    this.routeToSingleOrderPage(data["status"]);
                  } else {
                    this.showNotification('error', 'Error occurred! Please check and try again!');
                  }
                }
              );
            } else {
              this.showNotification('error', 'Date Exceeded. You can\'t place an order for this. **');
            }
          } else{
            this.showNotification('error', 'Warranty is not approved!');
          }
        } else {
          this.showNotification('error', 'Error when getting delivered date **');
        }
      }
    );
  }

  routeToSingleOrderPage(orderNumber: any) {
    this.router.navigate(['/admin/order_details'], {queryParams: {orderId: orderNumber}});
  }

  updateDeliveryStatusWithCod() {

    if(this.previousOrdersArray.length > 0){
      if(confirm("Did you checked the previous orders?")){
        this.serviceClass.updateDeliveryStatusWithCOD(this.orderId, this.agentEmail).subscribe(
          data => {
            if (data == true) {
              this.showNotification('success', 'New Cod number set to the order **');
              this.doWhenStart();
            } else {
              this.showNotification('error', 'No COD Numbers Available **');
            }
          }
        );
      }
    } else {
      this.serviceClass.updateDeliveryStatusWithCOD(this.orderId, this.agentEmail).subscribe(
        data => {
          if (data == true) {
            this.showNotification('success', 'New Cod number set to the order **');
            this.doWhenStart();
          } else {
            this.showNotification('error', 'No COD Numbers Available **');
          }
        }
      );
    }

  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "pending") {
      return "label-primary pending";
    } else if (deliveryStatus == "confirmed") {
      return "label-info confirmed";
    } else if (deliveryStatus == "dispatched") {
      return "label-warning dispatched";
    } else if (deliveryStatus == "delivered") {
      return "label-success delivered";
    } else if (deliveryStatus == "return") {
      return "label-danger return";
    } else if (deliveryStatus == "canceled") {
      return "label-warning canceled";
    } else if (deliveryStatus == "warrenty") {
      return "label-secondary warrenty";
    }
  }


  updateTimeLineDetails() {
    this.serviceClass.getOrderTimeline(this.orderId).subscribe(
      data => {
        this.timeLineDataArray = data;
      }
    );
  }

  removeAddedItem(itemId: any) {
    if (itemId == '1') {
      this.firstItem_edit = '';
      this.firstItem_name_edit = '';
      this.firstItemCount_edit = 0;
      this.firstItemNote_edit = "";
      this.item1PriceSub = 0;
    } else if (itemId == '2') {
      this.secondItem_edit = '';
      this.secondItem_name_edit = '';
      this.secondItemCount_edit = 0;
      this.secondItemNote_edit = "";
      this.item2PriceSub = 0;
    } else if (itemId == '3') {
      this.thirdItem_edit = '';
      this.thirdItem_name_edit = '';
      this.thirdItemCount_edit = 0;
      this.thirdItemNote_edit = "";
      this.item3PriceSub = 0;
    } else if (itemId == '4') {
      this.fourthItem_edit = '';
      this.fourthItem_name_edit = '';
      this.fourthItemCount_edit = 0;
      this.fourthItemNote_edit = "";
      this.item4PriceSub = 0;
    } else if (itemId == '5') {
      this.fifthItem_edit = '';
      this.fifthItem_name_edit = '';
      this.fifthItemCount_edit = 0;
      this.fifthItemNote_edit = "";
      this.item5PriceSub = 0;
    }
    this.updatePriceTable();
  }


  updateOrderDetails() {
    this.updatePriceTable();
    if (this.name_edit.length == 0) {
      this.showNotification('warning', 'Name is required **');
    } else if (this.phone_edit.length != 10) {
      this.showNotification('warning', 'Please enter valid phone number **');
    } else if (this.telephone_edit.length != 10) {
      this.showNotification('warning', 'Please enter valid telephone number **');
    } else if (this.deliveryDate_edit.length == 0) {
      this.showNotification('warning', 'Delivery Date is required **');
    } else if (this.city_edit == "City") {
      this.showNotification('warning', 'City is required **');
    } else if (this.firstItem_edit.length == 0 && this.secondItem_edit.length == 0 && this.thirdItem_edit.length == 0 && this.fourthItem_edit.length == 0 && this.fifthItem_edit.length == 0) {
      this.showNotification('warning', 'Atleast one item is required to place the order **');
    } else if (this.deliveryName.length == 0) {
      this.showNotification('warning', 'Delivery type is required **');
    } else if (this.firstItem_edit.length != 0 && this.firstItemCount_edit == 0) {
      this.step = 1;
      this.showNotification('warning', 'First item quantity required **');
    } else if (this.secondItem_edit.length != 0 && this.secondItemCount_edit == 0) {
      this.step = 2;
      this.showNotification('warning', 'Second item quantity required **');
    } else if (this.thirdItem_edit.length != 0 && this.thirdItemCount_edit == 0) {
      this.step = 3;
      this.showNotification('warning', 'Third item quantity required **');
    } else if (this.fourthItem_edit.length != 0 && this.fourthItemCount_edit == 0) {
      this.step = 4;
      this.showNotification('warning', 'Fourth item quantity required **');
    } else if (this.fifthItem_edit.length != 0 && this.fifthItemCount_edit == 0) {
      this.step = 5;
      this.showNotification('warning', 'Fourth item quantity required **');
    } else {

      this.dataToSendToServer = {
        orderNumber: this.orderId,
        device1: this.firstItem_edit,
        numDevice1: this.firstItemCount_edit,
        specialNote1: this.firstItemNote_edit,
        device2: this.secondItem_edit,
        numDevice2: this.secondItemCount_edit,
        specialNote2: this.secondItemNote_edit,
        device3: this.thirdItem_edit,
        numDevice3: this.thirdItemCount_edit,
        specialNote3: this.thirdItemNote_edit,
        device4: this.fourthItem_edit,
        numDevice4: this.fourthItemCount_edit,
        specialNote4: this.fourthItemNote_edit,
        device5: this.fifthItem_edit,
        numDevice5: this.fifthItemCount_edit,
        specialNote5: this.fifthItemNote_edit,
        totalCost: this.totalCost,
        totalAmount: this.totalPrice,
        totalProfit: (Number(this.totalPrice) - Number(this.totalCost)),
        customerName: this.name_edit,
        phoneNumber: this.phone_edit,
        fixedLine: this.telephone_edit,
        idNumber: this.nic_edit,
        addressNumber: this.addressNo_edit,
        addressFirstLine: this.firstLine_edit,
        addressSecondLine: this.secondLine_edit,
        city: this.city_edit,
        deliveryDate: this.deliveryDate_edit,
        deliveryType: this.deliveryId,
        specialNotes: this.specialNotes_edit,
        agent: this.agentEmail,
        OrderType: "Normal_Order",
        envelope: this.envelope
      };

      this.serviceClass.updateOrder(this.dataToSendToServer).subscribe(
        data => {
          if (data == true) {
            this.showNotification('success', 'Order Updated Successfully!');
            this.step = 12;
            window.scroll(0, 0);
            this.doWhenStart();
          } else {
            this.showNotification('error', 'Error occurred! Please check and try again!');
          }
        }
      );
    }
  }

  getUserNameFromEmail(email: any) {
    for (let users of this.userArray) {
      if (users["empEmail"] == email) {
        return users["empName"];
      }
    }
  }

  getDeliveryDetailsName(id: any) {
    for (let types of this.deliveryTypeArray) {
      if (types["id"] == id) {
        this.deliveryId = types["id"];
        this.deliveryPrice = Number(types["price"]);
        return types["name"];
      }
    }
  }

  setSelectBoxValues(dataType: any, item) {
    if (dataType == 'city') {
      this.city_edit = item["name"];
    } else if (dataType == 'item1') {
      this.firstItem_name_edit = item["name"];
      this.firstItem_edit = item["deviceId"];
      this.envelope = item["envelope"];
    } else if (dataType == 'item2') {
      this.secondItem_name_edit = item["name"];
      this.secondItem_edit = item["deviceId"];
    } else if (dataType == 'item3') {
      this.thirdItem_name_edit = item["name"];
      this.thirdItem_edit = item["deviceId"];
    } else if (dataType == 'item4') {
      this.fourthItem_name_edit = item["name"];
      this.fourthItem_edit = item["deviceId"];
    } else if (dataType == 'item5') {
      this.fifthItem_name_edit = item["name"];
      this.fifthItem_edit = item["deviceId"];
    } else if (dataType == 'deliveryType') {
      this.deliveryName = item["name"];
      this.deliveryId = item["id"];
      this.deliveryPrice = item["price"];
      this.updatePriceTable();
    } else {
      this.showNotification('warning', 'Please fix setSelectBoxValues method **');
    }

    if(this.secondItem_edit !== '' || this.thirdItem_edit !== '' || this.fourthItem_edit !== '' || this.fifthItem_edit !== ''){
      this.envelope = "select";
    }
  }

  updateNote(noteId: string) {
    this.dataToSendToServer = {
      orderNumber: this.orderId,
      agent: this.agentEmail,
      note1: this.note1Edit,
      note2: this.note2Edit,
      note3: this.note3Edit,
      noteId: noteId
    }

    this.serviceClass.updateNotes(this.dataToSendToServer).subscribe(
      data => {
        if (data) {
          this.showNotification('success', 'Notes Updated Successfully.');
          this.doWhenStart();
        } else {
          this.showNotification('error', 'Error occurred when updating the notes **');
        }
      }
    );
  }

  refreshButtonValues() {
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

    this.serviceClass.getAllCities().subscribe(
      data => {
        this.cityDataListJson = data;
      }
    );

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.deliveryTypeArray = data;
      }
    );
  }

  updatePriceTable() {
    let temporaryItemPricing: any;
    this.item1PriceSub = 0;
    this.item2PriceSub = 0;
    this.item3PriceSub = 0;
    this.item4PriceSub = 0;
    this.item5PriceSub = 0;
    this.totalCost = 0;

    if (this.deliveryPrice == undefined) {
      this.deliveryPrice = 0;
    }

    if (this.firstItem_edit != '' && this.firstItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.firstItem_edit);
      this.item1PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.firstItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.secondItem_edit != '' && this.secondItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.secondItem_edit);
      this.item2PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.secondItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.thirdItem_edit != '' && this.thirdItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.thirdItem_edit);
      this.item3PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.thirdItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.fourthItem_edit != '' && this.fourthItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.fourthItem_edit);
      this.item4PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.fourthItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.fifthItem_edit != '' && this.fifthItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.fifthItem_edit);
      this.item5PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.fifthItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    this.totalPrice = Number(this.item1PriceSub) + Number(this.item2PriceSub) + Number(this.item3PriceSub) + Number(this.item4PriceSub) + Number(this.item5PriceSub) + Number(this.deliveryPrice);

    if (this.orderType == "warrantyClaim") {
      this.item1PriceSub = 0;
      this.item2PriceSub = 0;
      this.item3PriceSub = 0;
      this.item4PriceSub = 0;
      this.item5PriceSub = 0;
      this.deliveryPrice = 0;
      if (this.warrantyTotalPrice != 0) {
        this.totalPrice = this.warrantyTotalPrice;
        this.totalCost = this.warrantyTotalPrice;
      } else {
        this.totalPrice = this.totalPriceTemp;
        this.totalCost = this.totalCostTemp;
      }
    }
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "Enter Item";
    }
  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  getInvoice() {
    this.routeToInvoice(this.orderId);
  }

  routeToInvoice(orderNumber: any) {
    this.router.navigate(['/invoice/show'], {queryParams: {orderId: orderNumber}});
  }

  openSingleAddressPrint() {
    let a = document.createElement("a");
    a.href = window.location.origin + "/#/address/show?orderId=" + this.orderId;
    let evt = document.createEvent("MouseEvents");
    //the tenth parameter of initMouseEvent sets ctrl key
    evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0,
      true, false, false, false, 0, null);
    a.dispatchEvent(evt);
  }

  openClientViewForUser() {
    let a = document.createElement("a");
    a.href = "https://track.buyzone.lk/?cod=" + this.cod_edit;
    let evt = document.createEvent("MouseEvents");
    //the tenth parameter of initMouseEvent sets ctrl key
    evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0,
      true, false, false, false, 0, null);
    a.dispatchEvent(evt);
  }

  returnOrderBlock(index: string) {
    if (index == "1") {
      this.serviceClass.updateReasonToReturnOrder(this.orderId, this.reasonToReturnOrder, "returnReason").subscribe(
        data => {
          this.serviceClass.updateReasonToReturnOrder(this.orderId, this.agentEmail, "agentEmail").subscribe(
            data => {
              if (data == true) {
                this.showNotification('success', 'Return reason updated successfully!');
              } else {
                this.showNotification('error', 'Error occurred! Please check and try again!');
              }
            }
          );
        }
      );
    } else if (index == "2") {
      this.serviceClass.updateReasonToReturnOrder(this.orderId, this.mistakeBy, "mistakeBy").subscribe(
        data => {
          if (data == true) {
            this.showNotification('success', 'Return mistake updated successfully!');
          } else {
            this.showNotification('error', 'Error occurred! Please check and try again!');
          }
        }
      );
    } else if (index == "3") {
      this.serviceClass.updateReasonToReturnOrder(this.orderId, this.agentEmail, "authorize").subscribe(
        data => {
          if (data == true) {
            this.showNotification('success', 'Return mistake updated successfully!');
          } else {
            this.showNotification('error', 'Error occurred! Please check and try again!');
          }
        }
      );
    }
    this.updateReturnReasonDetails();
  }

  updateReturnReasonDetails() {
    this.serviceClass.updateReasonsToReturnDetails(this.orderId).subscribe(
      data => {
        this.reasonToReturnOrder = data[0]["reasonToReturn"];
        this.mistakeBy = data[0]["mistakeBy"];
        this.AuthorizedBy = data[0]["authorizedBy"];
      }
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
