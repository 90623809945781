<div class="a4SizePage">

    <div class="addressBlock">
      <h2 class="addressDetailsElements"><i class='icon far fa-user'></i>
        &nbsp;&nbsp;&nbsp;{{clientName}}</h2>
      <h2 class="addressDetailsElements"><i class="icon fa fa-home"></i>
        &nbsp;&nbsp;&nbsp;{{addressNumber}}</h2>
      <h2 class="addressDetailsElements">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{firstLine}}</h2>
      <h2 class="addressDetailsElements">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{secondLine}}</h2>
      <h2 class="addressDetailsElements">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{city}}</h2>
      <h2 class="addressDetailsElements"><i class="icon fas fa-id-badge"></i>
        &nbsp;&nbsp;&nbsp;&nbsp;{{codNumber}}</h2>
      <h2 class="addressDetailsElements"><i class="icon fa fa-phone"></i> &nbsp;&nbsp;&nbsp;{{phoneNumber}}
        / {{telephone}}</h2>
      <h2 class="addressDetailsElements"><i class="icon far fa-money-bill-alt"></i>
        &nbsp;&nbsp;{{total}} LKR</h2>
      <hr>
      <h3 class="buyzoneNameStyle"><ngx-barcode [bc-height]="60"
                                                [bc-margin-bottom]="0"
                                                [bc-margin-top]="0"
                                                [bc-value]="codNumber"
                                                [bc-width]="2.6"></ngx-barcode></h3>
      <hr>
      <h3 class="buyzoneNameStyle"><img class="invoiceLogo" style="height: 60px;" data-holder-rendered="true" src="assets/images/logo/wide-logo-black.png"/>
      </h3>
    </div>
</div>
